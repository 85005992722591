import styled from 'styled-components';
import { Paragraph } from '../Typography';
import { blue, white } from 'theme/colors';
import { spacing05x, spacing1x, spacing2x } from 'theme/spacings';

export const ColorList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

export const Tooltip = styled.div`
	visibility: hidden;
	width: 30rem;
	background-color: ${blue};
	border-radius: 0.2rem;
	padding: ${spacing1x} ${spacing2x};
	position: absolute;
	z-index: 1;
	top: 4rem;
	left: -13.7rem;

	:after {
		content: '';
		position: absolute;
		bottom: 100%;
		left: 50%;
		margin-left: -1rem;
		border-width: 1rem;
		border-style: solid;
		border-color: transparent transparent ${blue} transparent;
	}
`;

export const ColorImg = styled.img`
	cursor: pointer;
	margin-right: ${spacing05x};
	padding: 0.2rem;
	height: 2.4rem;
	width: 2.4rem;
`;

export const ColorItem = styled.li`
	display: inline-block;
	position: relative;
	white-space: nowrap;

	:hover img {
		outline: 0.3rem solid ${blue};
	}

	:hover ${Tooltip} {
		visibility: visible;
	}
`;

export const Title = styled(Paragraph)`
	text-weight: normal;
	color: ${white};
	margin: 0;
`;

export const Text = styled(Paragraph)`
	color: ${white};
	margin: 0;
	display: flex;
`;

export const Property = styled.span`
	margin-right: ${spacing2x};
	width: 10rem;
`;

export const Value = styled.span``;
