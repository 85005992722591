/**
 * IframeBlock
 */

import clsx from 'clsx';
import Layout from 'components/ui/Layout';
import React from 'react';

interface Props {
	/** Iframe title */
	title: string;

	/** Iframe url */
	url: string;

	/** Optional className */
	className?: string;
}

/** Main description for this component. */
const IframeBlock = ({ title, url, className }: Props): JSX.Element => {
	return (
		<Layout className="pt-12">
			<iframe title={title} src={url} className={clsx('w-full', className)} />
		</Layout>
	);
};

export default IframeBlock;
