/**
 * OrderConfirmationPage
 * @module pages/OrderConfirmationPage
 */

import React, { useEffect } from 'react';
import Layout from 'components/ui/Layout';
//
import ContentArea from 'components/framework/ContentArea';
import RichText from 'components/ui/RichText';
import CustomExternalScript from 'components/framework/CustomExternalScript';
import Icon from 'components/ui/Icon';
import { OrderConfirmationPageModel } from 'types/page-types';

const OrderConfirmationPage = ({
	heading,
	text,
	message,
	contentArea,
	isInEditMode,
	order,
	orderId,
	framework,
}: OrderConfirmationPageModel) => {
	useEffect(() => {
		let items: any = [];

		order.articles?.map((art: any) => {
			return items.push({
				item_name: art.itemName,
				item_id: art.itemCode,
				price: art.prices.grossPriceFormatted,
				item_variant: art.attributeName,
				quantity: art.quantity,
			});
		});

		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'purchase',
				domain: framework?.header.activeMarket,
				ecommerce: {
					currency: order.currency,
					value: order.total,
					tax: order.tax,
					shipping: order.shipping,
					transaction_id: order.trackingId,
					items: items,
				},
			});
		}
		//eslint-disable-next-line
	}, []);

	return (
		<main id="main-content">
			<Layout backgroundColor="white">
				<div className="md:w-160 max-w-full">
					{heading && (
						<div className="flex items-center justify-between">
							{heading && (
								<h1 className="font-standard mb-6 mt-10 font-normal text-h2 md:text-h1 text-blue">
									{heading}
									{orderId && (
										<span style={{ display: 'block' }}>{orderId}</span>
									)}
								</h1>
							)}

							<div>
								<Icon icon="check" color="green" className="w-32 h-32" />
							</div>
						</div>
					)}

					{text && (
						<RichText
							name="text"
							size="large"
							text={text}
							className="m-0"
							isInEditMode={isInEditMode}
						/>
					)}
					<CustomExternalScript />
					{message && <p className="text-red">{message}</p>}
				</div>
			</Layout>
			{contentArea && (
				<div className="mt-10">
					<ContentArea contentArea={contentArea} />
				</div>
			)}
		</main>
	);
};

export default OrderConfirmationPage;
