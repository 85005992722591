/**
 * MediaModal
 * @module components/MediaModal
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
	Dispatch,
	MouseEvent,
	SetStateAction,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { LocalizationContext } from 'context/localization.context';
import { PlayIcon } from './MediaGallery.styles';
import { Modal as SimpleModal } from 'components/ui/SimpleModal/Modal';
import Icon from 'components/ui/Icon';
import clsx from 'clsx';
import { MediaGalleryItemModel } from 'types/common';

export interface MediaModalProps {
	item: MediaGalleryItemModel;
	toggleModal: () => void;
	showPreviousMedia: (e: MouseEvent<HTMLElement>) => void;
	modalActive: boolean;
	currentValues: Record<string, unknown>;
	showNextMedia: (e: MouseEvent<HTMLElement>) => void;
	isAutoplay: number;
	setAutoplay: Dispatch<SetStateAction<number>>;
}

const MediaModal = ({
	item,
	toggleModal,
	showPreviousMedia,
	modalActive,
	currentValues,
	showNextMedia,
	isAutoplay,
	setAutoplay,
}: MediaModalProps) => {
	const { t } = useContext(LocalizationContext as any);
	const isMobile = window.innerWidth <= 768;
	const handlePreviousClick = (e: MouseEvent<HTMLElement>) => {
		showPreviousMedia(e);
		setAutoplay(0);
	};

	const handleNextClick = (e: MouseEvent<HTMLElement>) => {
		showNextMedia(e);
		setAutoplay(0);
	};
	const mediaModalIframeRef = useRef<HTMLIFrameElement>(null);
	const [isLoading, setIsLoading] = useState(true); // Initial3d iframe loading state

	useEffect(() => {
		const handleReadyMessage = (event: MessageEvent) => {
			if (event.data?.ready) {
				setIsLoading(false); // set iframe loading to false
			}
		};

		window.addEventListener('message', handleReadyMessage);

		// Cleanup function to remove event listener on unmount
		return () => {
			window.removeEventListener('message', handleReadyMessage);
		};
	}, []);

	const handleOnLoad = () => {
		setTimeout(() => {
			setIsLoading(true);
		}, 500);
	};

	useEffect(() => {
		if (item.type === '3DConfig' && mediaModalIframeRef && !isLoading) {
			const iframeWindow = mediaModalIframeRef?.current?.contentWindow;
			iframeWindow?.window?.postMessage(
				JSON.stringify(currentValues),
				item?.url as string
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	return (
		<SimpleModal.Frame open={modalActive} onClose={toggleModal}>
			<SimpleModal.Body>
				<div
					className={clsx(
						'relative bg-white rounded image-modal flex flex-col justify-center items-center'
					)}
				>
					<div className="w-full h-full overflow-hidden">
						{item.type === 'Image' ? (
							<figure>
								<source
									type="image/webp"
									srcSet={`${item.webpSizes[0]} 1200w, ${item.webpSizes[1]} 800w, ${item.webpSizes[2]} 400w`}
									sizes="(max-width: 767px) 60vw, (min-width: 768px) and (max-width: 1024px) 70vw, 1200px"
								/>
								<img
									className="h-full py-6 mx-auto image-objectfit"
									src={item.src}
									srcSet={`${item.sizes[0]} 1200w, ${item.sizes[1]} 800w, ${item.sizes[2]} 400w`}
									sizes="(max-width: 767px) 60vw, (min-width: 768px) and (max-width: 1024px) 70vw, 1200px"
									alt={item.alt}
								/>
							</figure>
						) : item.type === '3DConfig' ? (
							<iframe
								className="h-full w-full py-6 mx-auto"
								title={item.title}
								id={item.id}
								ref={mediaModalIframeRef}
								onLoad={handleOnLoad}
								src={item.url}
							></iframe>
						) : (
							<iframe
								className="h-full w-full absolute top-0 bottom-0 left-0"
								title="productVideo"
								src={`${item.url}?autoplay=${isAutoplay}`}
								allowFullScreen
							>
								<PlayIcon iconsize="large" />
							</iframe>
						)}
						<button
							className="absolute top-0 right-0 p-1 bg-transparent hover:bg-greyDark"
							aria-label={t('shared/modal/closemodal')}
							onClick={(e) => {
								e.preventDefault();
								toggleModal();
							}}
						>
							<Icon icon="close" color="#949494" size={isMobile ? 1 : 2} />
						</button>
						<button
							className="absolute left-0 p-3 transform -translate-y-1/2 bg-transparent top-1/2 hover:bg-greyDark"
							onClick={(e) => {
								handlePreviousClick(e);
							}}
							title={t('shared/modal/showpreviousmedia')}
						>
							<Icon
								icon="chevron"
								direction="right"
								color="#949494"
								size={isMobile ? 1 : 2}
							/>
						</button>
						<button
							className="absolute right-0 p-3 transform -translate-y-1/2 bg-transparent top-1/2 hover:bg-greyDark"
							onClick={(e) => {
								handleNextClick(e);
							}}
							title={t('shared/modal/shownextmedia')}
						>
							<Icon
								icon="chevron"
								direction="left"
								color="#949494"
								size={isMobile ? 1 : 2}
							/>
						</button>
					</div>
				</div>
			</SimpleModal.Body>
		</SimpleModal.Frame>
	);
};

export default MediaModal;
